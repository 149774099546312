import React from 'react';
import './Footer.css';
import pradco_logo_1 from '../assets/home/pradco_logo_1.png';
import pradco_logo_2 from '../assets/home/pradco_logo_2.png';
import ind_flag from '../assets/home/ind_flag.png';

function Footer() {
  return (
    <div className='footer'>
      <div className='row'>
      <div className='column'>   
     
        <h2 id='company'>Company</h2>
        <a  href='/about'>About Us</a>
        <a  href='/careers'>Careers</a>
        <a href='/terms'>Terms of Service</a>
        <a href='/privacy'>Privacy Policy</a>
      </div>


      <div className='column'>        
        <h2>Products & Solutions</h2>
        <a href='/paintsprayers'>Paint Sprayers</a>
        <a href='/accessories'>Accessories</a>
        <a href='/spray_tip'>Spray Tip</a>
        <a href='/product_catalogue'>Product Catalogue</a>
        <a  href='/homeowner'>Home Owner</a>
        <a  href='/contractor'>Contractor</a>
        <a  href='/vehicle'>Vehicle Service & Heavy Equipment</a>
        <a  href='/industry'>Industrial, Manufacturing & Processing</a>
      </div>

      <div className='column support'>        
        <h2>Support</h2>
        <a>Find Manuals & Parts</a>
        <a href='/faq'>Frequently Asked Questions</a>
        <a  href='/contact'>Contact Us</a>
        <a >Toll-Free : 18003097372</a>
       
      </div>
      </div>
      <div className='bottom'>
      <img src={pradco_logo_1} className="foot-logo" alt="logo" />
    <img src={ind_flag} className="flag" alt="logo" /> 
       <h3 id='flag_tag'>Made in India </h3>

      <p id='rights'>2024 © Pradco Pvt Ltd. All rights reserved.</p>
    </div>
    
    </div>
  )
}

export default Footer