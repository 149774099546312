import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {Home, HomeOwner, Contractor, Vehicle, Industry, About, Careers, PaintSprayers, Accessories, SprayTip,ProductCatalogue, Contact, TermsOfUse, PrivacyPolicy, FAQ} from './screens';
import EmailForm from './components/Contact/emailceoform';
import JobDescription from './components/Careers/job_desc';
import Apply from './components/Careers/apply';


function App() {
  
  return (
    
    <Router>
    <div className="App">
      <Switch>
        <Route exact path="/"> <Home/>  </Route>
        <Route exact path="/homeowner"> <HomeOwner/> </Route>
        <Route exact path="/contractor"> <Contractor/> </Route>
        <Route exact path="/vehicle"> <Vehicle/> </Route>
        <Route exact path="/industry"> <Industry/> </Route>
        <Route path="/about"> <About/>  </Route>
        <Route path="/careers"> <Careers/>  </Route>
        <Route path="/paintsprayers"> <PaintSprayers/>  </Route>
        <Route path="/accessories"> <Accessories/>  </Route>
        <Route path="/spray_tip"> <SprayTip/>  </Route>
        <Route path="/product_catalogue"> <ProductCatalogue/>  </Route>
        <Route path="/contact"> <Contact/>  </Route>
        <Route path="/terms"> <TermsOfUse/>  </Route>
        <Route path="/privacy"> <PrivacyPolicy/>  </Route>
        <Route path="/faq"> <FAQ/>  </Route>
        <Route path="/emailCEO"> <EmailForm/>  </Route>
        <Route path="/jobdescription"> <JobDescription/>  </Route>
        <Route path="/apply"> <Apply/>  </Route>
      </Switch>
    </div>
    </Router>
  
  );
}

export default App;
