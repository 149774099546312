import React from 'react'
import './header.css'
import pradco_logo from '../assets/home/pradco_logo.png';
import hamburger from '../assets/home/hamburger.png';
import close from '../assets/home/close.jpg';

function Header() {
  return (
    <div className='mob_header'>
   <a href='/'><img src={pradco_logo} id='logo_hmob'/></a>
   
  <ul class="navbar" id="navbar">
  <a class="close" href="#">
      <img src={close} alt="close"/>
    </a>
    <li><a href="/">HOME</a></li>
    <li><a href="/paintsprayers">PAINT SPRAYERS</a></li>
    <li><a href="/accessories">ACCESSORIES</a></li>
    <li><a href="/spray_tip">SPRAY TIP</a></li>
    <li><a href="/product_catalogue">PRODUCT CATALOGUE</a></li>
    <li><a href="/homeowner">HOME OWNER</a></li>
    <li><a href="/contractor">CONTRACTOR</a></li>
    <li><a href="/vehicle">VEHICLE WASHING & AGRI-TECH EQUIPMENTS</a></li>
    <li><a href="/industry">INDUSTRIAL MANUFACTURING & PROCESSING</a></li>
    <li><a href="/about">ABOUT US </a></li>
    <li><a href="/careers">CAREERS</a></li>
    <li><a href="/contact">CONTACT</a></li>
    <li><a href="https://pradco-dashboard.web.app/" target="_blank" >For Distributors </a></li>

    
  </ul>
  <a class="hamburger" href="#navbar">
      <img src={hamburger} alt="menu"/>
  </a>
    </div>
  )
}

export default Header