import React from "react";
import phone from "../../assets/contact/phone.png";
import sales from "../../assets/contact/sales.png";
import mail from "../../assets/contact/mail.png";
import customer_service from "../../assets/contact/customer_service.png";

function Sales() {
  return (

        <div  className="con_flex">
      <div className="indi">
        <img src={customer_service} alt="phone" />
        <h2>
          CUSTOMER <br />
          SERVICE
        </h2>
        <p className="indi_p">
          Find local service support
          <br />
 Toll free number : 18003097372<br/>
          Monday - Friday 9:00 am - 6:00 pm
        </p>
        <a className="need"><h3>NEED A SERVICE</h3><hr/></a>
      </div>

      <div className="indi">
        <img src={customer_service} alt="phone" />
        <h2>
        TECHNICAL <br/>SUPPORT
        </h2>
        <p className="indi_p">
          Find local technical support
          <br />
 Toll free number : 18003097372<br/>
          Monday - Friday 9:00 am - 6:00 pm
        </p>
        <a className="need"><h3>NEED A SERVICE</h3><hr/></a>
      </div>

      <div className="indi">
        <img src={mail} alt="phone" id='mail-img'/>
        <h2>
        WANT TO SPEAK <br/>BUSINESS?
        </h2>
        <p className="indi_p">
        Book a slot to have a chat!
        </p>
        <a className="need" href="https://cal.com/pradco" target='blank'><h3>BOOK A SLOT</h3><hr/></a>
      </div>

      <div className="indi">
        <img src={sales} alt="phone" id='sales-img'/>
        <h2>
        SALES
        </h2><br/>
        <p className="indi_p">
        Evaluating our products and
 need advice<br/>
 Toll free number : 18003097372
 

        </p>
        <a className="need" href="https://cal.com/pradco" target='blank'><h3>BOOK A SLOT</h3><hr/></a>
      </div>
      </div>

  );
}

export default Sales;
